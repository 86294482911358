define('llwww/services/news-page', ['exports', 'ember'], function (exports, _ember) {
   /**
    * News Page service.  Emulating pagination with json files.
    * The json files exist in /assets/news, and have the file name format:
    *    announcements.0.json
    *    announcements.1.json etc..
    * This Service acts as the interface between the route and the static files.
    * Also, the items are 'cached' as properties on this service.
    */
   exports['default'] = _ember['default'].Service.extend({

      announcements: {
         items: _ember['default'].A(),
         hasMore: false,
         page: 0
      },

      awards: {
         items: _ember['default'].A(),
         hasMore: false,
         page: 0
      },

      articles: {
         items: _ember['default'].A(),
         hasMore: false,
         page: 0
      },

      /**
       * Gets the next page for a specified category.
       * @param  {String} category The category to retrieve (announcements, awards, articles)
       * @return {Object}          Object representing a category, with structure:
       *                                  {
       *                                     items: [],
       *                                     hasMore: Boolean,
       *                                     page: Number
       *                                  }
       */
      getNextPage: function getNextPage(category) {
         var _this = this;

         var model = this.get(category);

         return this.getCategoryPage(category, model.page++).then(function (json) {
            _this.get(category + '.items').addObjects(json.items);
            _this.set(category + '.hasMore', json.hasMore);
            _this.set(category + '.page', model.page);
            return model;
         });
      },

      /**
       * Gets all pages loaded for a category.
       * Or, if none loaded, fetch the first page.
       *
       * @param  {String} category The category to retrieve (announcements, awards, articles)
       * @return {Object}          Object representing a category, with structure:
       *                                  {
       *                                     items: [],
       *                                     hasMore: Boolean,
       *                                     page: Number
       *                                  }
       */
      getPagesLoaded: function getPagesLoaded(category) {
         var model = this.get(category);
         if (model.items.length === 0) {
            return this.getNextPage(category);
         } else {
            return this.get(category);
         }
      },

      /**
       * Performs a GET request to retrieve a page for a category.
       * Returns a promise.
       *
       * @param  {String} category The category to retrieve (announcements, awards, articles)
       * @param  {Number} page     The page number to retrieve.
       * @return {Promise}         Promise that fulfills with a json object.
       */
      getCategoryPage: function getCategoryPage(category, page) {
         return _ember['default'].$.getJSON('/assets/news/' + category + '.' + page + '.json');
      },

      /**
       * Retrieves a news item of a category with an id.
       * If the news item is in the cache, then we return that.
       * Else, the page with that item has not been loaded, so we
       * attempt to retrieve that page, and the item from that page.
       *
       * @param  {String} category The category to retrieve (announcements, awards, articles)
       * @param  {Number} id       The id of the item in the category.
       * @return {Object}          Object representing the news item. If fail, return false.
       */
      getNewsItem: function getNewsItem(category, id) {
         var model = this.get(category);
         if (id > model.items.length - 1) {
            var pageRequired = Math.floor(id / 5);
            return this.getCategoryPage(category, pageRequired).then(function (json) {
               var index = id % 5;
               if (index > json.items.length - 1) {
                  throw new Error('Item doesn\'t exist');
               }
               return json.items[id % 5];
            });
         } else {
            return model.items[Number(id)];
         }
      }
   });
});