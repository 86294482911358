define('llwww/services/email', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Service.extend({

      /**
       * Send partner contact request email
       * @param  {string} email email of the requestee
       * @return {Promise}      Promise that resolves when email is sent
       */
      sendPartnerContactRequest: function sendPartnerContactRequest(email) {
         return new _ember['default'].RSVP.Promise(function (resolve, reject) {
            // use emailjs to send email.
            emailjs.send('gmail', 'partners_contact', { email: email }).then(resolve, reject);
         });
      }
   });
});