define("llwww/properties", ["exports", "ember"], function (exports, _ember) {
   exports["default"] = _ember["default"].Object.create({
      cni: {
         links: [{
            name: "carrier.att",
            url: "https://www.att.com/shop/wireless/smartlimits.html"
         }, {
            name: "carrier.verizon",
            url: "https://www.verizonwireless.com/solutions-and-services/family-base/"
         }, {
            name: "carrier.sprint",
            url: "http://shop.sprint.com/mysprint/services_solutions/details.jsp?detId=mobile_controls&catId=service_safety_control&catName=Safety%20and%20Control&detName=Sprint%20Mobile%20Controls&specialCat="
         }]
      },

      locator: {
         links: [{
            name: "carrier.att",
            url: "https://www.att.com/shop/apps/familymap.html"
         }, {
            name: "carrier.tmobile",
            url: "https://play.google.com/store/apps/details?id=com.wavemarket.finder.mobile"
         }, {
            name: "carrier.sprint",
            url: "https://sprint-locator.safely.com/"
         }]
      },

      social: {
         github: "https://github.com/locationlabs",
         instagram: "https://www.instagram.com/locationlabslife",
         instagramUserName: "@locationlabslife",
         linkedin: "https://www.linkedin.com/company/location-labs"
      },

      partners: {
         links: [{
            name: "carrier.verizon",
            url: "https://www.verizonwireless.com/solutions-and-services/family-base/"
         }, {
            name: "carrier.att",
            url: "https://www.att.com/shop/wireless/smartlimits.html"
         }, {
            name: "carrier.tmobile",
            url: "https://play.google.com/store/apps/details?id=com.wavemarket.finder.mobile"
         }, {
            name: "carrier.sprint",
            url: "https://sprint-locator.safely.com/"
         }]
      },

      avast: {
         links: {
            careers: "https://careers.avast.com/our-vacancies/list/?&keywords=&location[]=17"
         }
      },

      corporate: {
         address: {
            city: "Emeryville",
            country: "USA",
            state: "California",
            stateAbbr: "CA",
            street: "2100 Powell St. Fl 14",
            zip: "94608",
            location: {
               lat: 37.839485,
               lng: -122.298357
            }
         },

         links: {
            blog: "http://www.engbloglocationlabs.com",
            greenhouse: "https://boards.greenhouse.io/locationlabs",
            privacy: "https://www.avast.com/privacy-policy"
         },

         email: {
            contact: "contact@locationlabs.com",
            help: "help@locationlabs.com",
            press: "press@locationlabs.com"
         }
      }
   });
});