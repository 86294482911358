define('llwww/services/instagram', ['exports', 'ember', 'llwww/config/environment'], function (exports, _ember, _llwwwConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    ajax: _ember['default'].inject.service(),

    /**
    * Performs a GET request to retrieve recent LL instagram images
    * Returns a promise.
    * @return {Promise}      Promise that resolves with result of
    * request
    */
    getRecentMedia: function getRecentMedia() {
      var gatewayUrl = _llwwwConfigEnvironment['default'].APP.GATEWAY.base_url;
      var endpointUrl = gatewayUrl + '/instagram/media';
      return this.get('ajax').request(endpointUrl);
    }
  });
});