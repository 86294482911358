define('llwww/components/highlight-box-list', ['exports', 'ember'], function (exports, _ember) {
   /*
      Hightlight-box-list is a component that is to be rendered inside
      of a hightlight-box in it's 'content' section.
   
      This renders a list of descriptions.
   */
   exports['default'] = _ember['default'].Component.extend({

      classNames: ['highlight-list'],

      // The path to the highligh-box object in the translations file.
      path: null,

      descriptions: _ember['default'].computed('path', function () {
         var _descriptions = [];
         for (var i = 1; i <= 3; i++) {
            _descriptions.push({
               header: this.get('path') + '.' + i + '.header',
               description: this.get('path') + '.' + i + '.description'
            });
         }
         return _descriptions;
      })

   });
});