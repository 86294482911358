define('llwww/components/highlight-box-links', ['exports', 'ember'], function (exports, _ember) {
   /*
      Hightlight-box-links is a component that is to be rendered inside
      of a hightlight-box in it's 'content' section.
   
      This renders a description and an array of links.
   */
   exports['default'] = _ember['default'].Component.extend({

      classNames: ['highlight-links'],
      /*
      links: [
         url: null,
         name: null
      ]
      */
      links: [],

      // The path to the highligh-box object in the translations file.
      path: null,

      description: _ember['default'].computed('path', function () {
         return this.get('path') + '.description';
      }),

      tryit: _ember['default'].computed('path', function () {
         return this.get('path') + '.try.it';
      })

   });
});