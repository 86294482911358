define('llwww/entities/highlight-box', ['exports', 'ember', 'llwww/properties'], function (exports, _ember, _llwwwProperties) {
   /*
      Used as a standardized object structure render a highlight-box.
   */
   exports['default'] = _ember['default'].Object.extend({

      name: null,

      links: _ember['default'].computed('name', function () {
         return _llwwwProperties['default'][this.get('name')].links;
      })

   });
});