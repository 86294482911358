define('llwww/components/image-slideshow', ['exports', 'ember'], function (exports, _ember) {

   /**
    *  Component Image-Slideshow
    *
    *  Renders a slideshow of images.  Pass the urls of your images to the 'images' property.
    */
   exports['default'] = _ember['default'].Component.extend({

      tagName: 'div',

      classNames: ['image-slideshow'],

      /**
       * The index of the current image displayed.
       * @type {Number}
       */
      activeImageId: 0,

      /**
       * The length of time in milliseconds to display each image.
       * @type {Number}
       */
      imageDisplayLength: 3000,

      /**
       * The image class names and/or external image URLs to use for the slideshow.
       * @type {Array}
       */
      imageNames: [],

      /**
       * Creates an array of image objects to render correctly.
       * @type  {Array}
       */
      images: _ember['default'].computed('imageNames', function () {

         var names = this.get('imageNames');
         var images = [];

         var ImageObject = _ember['default'].Object.extend({
            name: null,
            id: null,
            active: false,
            url: null,
            link: null
         });

         for (var i = 0; i < names.length; i++) {
            var image = ImageObject.create();
            var http = /http/;
            if (!http.exec(names[i])) {
               image.set('name', names[i]);
            } else {
               image.set('name', 'image-instagram');
               image.set('link', names[i]);
               image.set('url', names[i] + 'media');
            }
            image.set('id', i);
            image.set('active', i === this.get('activeImageId'));
            images.push(image);
         }
         return images;
      }),

      /**
       * Used to hold the last time a transition occurred. This is used so we
       * can determine a certain amount of time has passed and when to transition
       * to the next image.
       * @type {Integer}
       */
      _lastTransitionTime: null,

      /**
       * The current time since the window was opened. This is set in the function called
       * by window.requestAnimationFrame.
       * @type {Integer}
       */
      _currentTime: null,

      /**
       * Runs only after the component successfully renders its backing HTML into the DOM.
       * This is when we start the animation loop for the slide show.
       */
      didInsertElement: function didInsertElement() {
         var _this = this;

         window.requestAnimationFrame(function (time) {
            _this.onFrameUpdate(time);
         });
      },

      /**
       * Helper function for transitioning to the next image past
       * 'activeImageId'.
       */
      nextImage: function nextImage() {
         var nextImage = this.get('activeImageId');
         this.transitionToImage(++nextImage % this.get('images').length);
      },

      /**
       * Uses data-slide-id attribute to set item-active.
       * Done because we want to have css transitions,
       * and not trigger a re-render by updating a property.
       *
       * @param  {Number} nextId The id of the next image.
       */
      transitionToImage: function transitionToImage(nextId) {

         if (this.get('isDestroyed')) {
            return;
         }

         this.set('_lastTransitionTime', this.get('_currentTime'));
         this.set('activeImageId', nextId);

         this.$('.image-slideshow-nav-item').removeClass('item-active');

         this.$('.image-slideshow-item').removeClass('item-active');
         this.$('.image-slideshow-nav-item[data-slide-id="' + nextId + '"]').addClass('item-active');
         this.$('.image-slideshow-item[data-slide-id="' + nextId + '"]').addClass('item-active');
      },

      /**
       * The function called by window.requestAnimationFrame.
       *
       * Using window.requestAnimationFrame to check and keep track of the time.
       * This functions the same as 'setTimeout', except we are manually
       * keeping track of the time with _currentTime and _lastTransitionTime.
       * This is more performant if length of timeout is short and callback takes a long time.
       *
       * Used to determine if the slideshow shouldTransition to the
       * next image.
       * @param  {Number} time This is passed in by window.requestAnimationFrame
       */
      onFrameUpdate: function onFrameUpdate(time) {
         var _this2 = this;

         // If the component has been destroyed, stop!
         if (this.get('isDestroyed')) {
            return;
         }

         if (time === undefined) {
            time = Date.now();
         }

         if (this.get('_lastTransitionTime') === null) {
            this.set('_lastTransitionTime', time);
         }

         this.set('_currentTime', time);

         // Transition if time since last transition is greater than imageDisplayLength
         var shouldTransition = Number.parseInt(time - this.get('_lastTransitionTime')) > this.get('imageDisplayLength');

         if (shouldTransition) {
            this.nextImage();
         }

         // Call this function again when the next animation frame is ready.
         window.requestAnimationFrame(function (time) {
            _this2.onFrameUpdate(time);
         });
      },

      actions: {

         /**
          * @param  {Number} Id of image to transition to.
          */
         transitionToImage: function transitionToImage(id) {
            this.transitionToImage(id);
         }
      }
   });
});