define('llwww/controllers/partners', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Controller.extend({

      emailService: _ember['default'].inject.service('email'),

      email: '',

      emailFormSubmitted: false,

      actions: {
         submitEmail: function submitEmail() {
            this.get('emailService').sendPartnerContactRequest(this.get('email'));

            this.set('email', '');
            this.set('emailFormSubmitted', true);
         }
      }
   });
});