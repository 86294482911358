define('llwww/components/highlight-box', ['exports', 'ember'], function (exports, _ember) {
   /*
      A highlight box is a component that contains an image section and a text section.
      It is used on the products and partners pages.
   */
   exports['default'] = _ember['default'].Component.extend({

      tagName: 'section',

      classNames: ['highlight-box'],

      // The path to the highlight-box object in the translations file.
      path: null,

      header: _ember['default'].computed('path', function () {
         return this.get('path') + '.header';
      }),

      tagline: _ember['default'].computed('path', function () {
         return this.get('path') + '.tagline';
      })

   });
});