define('llwww/mixins/news-item', ['exports', 'ember'], function (exports, _ember) {

   /**
    * Mixin for an individual news item route.
    * Defines functions for retrieving a model,
    * as well as transitioning to and from the route.
    */
   exports['default'] = _ember['default'].Mixin.create({

      newsPage: _ember['default'].inject.service(),

      /**
       * Open modal when we transition to this route.
       */
      afterModel: function afterModel(model, transition) {
         transition.then(function () {
            _ember['default'].$('body').addClass('modal-on');
         });
      },

      /**
       * Close modal when we leave this route.
       */
      deactivate: function deactivate() {
         _ember['default'].$('body').removeClass('modal-on');
      },

      actions: {
         error: function error() {
            this.transitionTo('news');
         }
      }
   });
});