define('llwww/components/nav-link', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({

      tagName: 'li',

      /*
         The action to be called when this link is clicked.
         Passed down from containing component.
      */
      clickAction: null,

      name: null,

      /*
         Get route name based on name.  'Home'=='index'
      */
      routeName: _ember['default'].computed('name', function () {
         if (this.get('name') === 'home') {
            return 'index';
         } else {
            return this.get('name');
         }
      }),

      /*
         Get translation path based on name.
      */
      linkName: _ember['default'].computed('name', function () {
         return 'nav.' + this.get('name');
      })
   });
});