define('llwww/controllers/careers', ['exports', 'ember', 'llwww/properties'], function (exports, _ember, _llwwwProperties) {
   exports['default'] = _ember['default'].Controller.extend({

      /**
       * Google Analytics Service
       * @type {GAService}
       */
      gaService: null,

      actions: {
         /**
          * Handle view positions link click
          */

         onViewPositionsLinkClick: function onViewPositionsLinkClick() {
            window.open(_llwwwProperties['default'].get("avast.links.careers"), '_blank');
         }
      }
   });
});