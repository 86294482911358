define("llwww/locales/en/translations", ["exports"], function (exports) {
  exports["default"] = {

    // NAVIGATION

    "nav.about": "About Us",
    "nav.blog": "Blog",
    "nav.careers": "Careers",
    "nav.home": "Home",
    "nav.partners": "Partners",
    "nav.products": "Products",

    // FOOTER

    "footer.copyright": "&#169; {{year}} Location Labs. All Rights Reserved.",
    "links.about.name": "About",
    "links.careers.name": "Careers",
    "links.contact.name": "Contact Us",
    "links.index.name": "Home",
    "links.news.name": "News",
    "links.partners.name": "Partners",
    "links.privacy.name": "Privacy",
    "links.products.name": "Products",
    "links.github.name": "Github",
    "links.instagram.name": "Instagram",
    "links.linkedin.name": "LinkedIn",

    "landing.hiring": "We're hiring",
    "landing.tagline": "Mobile Security for humans",
    "landing.link.products": "See Our Products",

    // COMMON
    "carrier.att": "AT&amp;T",
    "carrier.verizon": "Verizon",
    "carrier.sprint": "Sprint",
    "carrier.tmobile": "T-Mobile",

    //PARTNERS: Header
    "partners.header": "Meaningful mobile engagement",
    "partners.description": "We engender loyalty with millions of families",

    //PARTNERS: Hover image
    "partners.hover.image.description": "We provide best practices to increase awareness and education of products that are core to our partners' growth.",

    //PARTNERS: Call-to-action
    "partners.form.header": "Leave us your email &amp; we'll get in touch",
    "partners.form.tagline": "Discover what our superpowers can do for your business",
    "partners.form.email": "Email address",
    "partners.form.submit": "Submit",
    "partners.form.thank.you": "Thank you!",

    //PARTNERS: How we do it
    "partners.how.we.do.it.header": "How we do it",
    "partners.how.we.do.it.tagline": "We are great partners",
    "partners.how.we.do.it.description": "Tier 1 operators worldwide partner with us to provide subscription-based, mobile device management and location services to millions of customers.",
    "partners.how.we.do.it.try.it": "Take a look",

    //PARTNERS: First icon-description box
    "partners.mobile.operator.header": "Mobile operator magic",
    "partners.mobile.operator.description": "We unleash the power of the mobile operator network to deliver essential services that users cannot get from an on-device app.",
    "partners.mobile.operator.cdr.header": "CDR &amp; location",
    "partners.mobile.operator.cdr.description": "Only operator services can access call detail records and location information from the network, for rich customer experiences no over-the-top app provider can replicate.",
    "partners.mobile.operator.data.header": "Data plan management",
    "partners.mobile.operator.data.description": "Only operator services are integrated with customers’ billing and account information, for easy purchase, installation, and provisioning on every phone in an account-holder’s plan.",
    "partners.mobile.operator.distribution.header": "Distribution",
    "partners.mobile.operator.distribution.description": "Customers will add more lines if assured that operators will provide the tools necessary to make phones safe and appropriate for everyone, especially kids.",

    //PARTNERS: Second icon-description box
    "partners.supercharge.business.header": "Supercharge your core business",
    "partners.supercharge.business.description": "We make the mobile device a safety device that drives billions of interactions. We help you deliver an end-to-end product that your most important customers love.",
    "partners.supercharge.business.margins.header": "Better ARPU &amp; margins",
    "partners.supercharge.business.margins.description": "Our family safety services are among the highest revenue services for carriers.",
    "partners.supercharge.business.engagement.header": "Higher engagement",
    "partners.supercharge.business.engagement.description": "Users log onto our carrier-branded services an average of 3.4 times per week.",
    "partners.supercharge.business.growth.header": "Gross add growth",
    "partners.supercharge.business.growth.description": "Our services are THE REASON that parents buy phones for their kids.",

    // PRODUCTS: Header
    "products.header": "Smart tools for mobile families",
    "products.description": "Millions rely on our solutions to manage daily family life.",

    // PRODUCTS: Footer
    "products.footer.header": "A mobile operator must-have",
    "products.footer.description": "Today's mobile operators handle petabytes of data, but it's only useful once it's processed and analyzed. Our products do just that, turning raw information into value for subscribers and operators alike.",
    "products.footer.icons.product.margin": "Better ARPU &amp; margins",
    "products.footer.icons.product.retention": "Higher retention",
    "products.footer.icons.product.growth": "Gross add growth",

    // PRODUCTS: CNI: About
    "products.cni.about.header": "Mobile Controls",
    "products.cni.about.tagline": "Analyze &amp; control",
    "products.cni.about.description": "Real-time data on how your phones are being used— with the power to limit when and how often. We enable you to protect your kids, and family, and manage their usage.",
    "products.cni.about.try.it": "Try it out",

    // PRODUCTS: CNI: How It Works
    "products.cni.how.it.works.header": "How it works",
    "products.cni.how.it.works.tagline": "Manage family phone use",
    "products.cni.how.it.works.1.header": "Insights",
    "products.cni.how.it.works.1.description": "See all activity: texts, calls, apps... in easy-to-read reports",
    "products.cni.how.it.works.2.header": "Controls",
    "products.cni.how.it.works.2.description": "Automatically lock phone during school or family time",
    "products.cni.how.it.works.3.header": "Alerts",
    "products.cni.how.it.works.3.description": "Be informed about questionable activities or contacts",

    // PRODUCTS: LOCATOR: About
    "products.locator.about.header": "Locator",
    "products.locator.about.tagline": "Four billion+ locates",
    "products.locator.about.description": "Pinpoint the people who matter most. LOCATOR has been adopted by every leading mobile operator and is the largest service of its kind.  We deliver more than 1 billion location lookups every year.",
    "products.locator.about.try.it": "Try it out",

    // PRODUCTS: LOCATOR: How It Works
    "products.locator.how.it.works.header": "How it works",
    "products.locator.how.it.works.tagline": "On Demand Location",
    "products.locator.how.it.works.1.header": "Locate",
    "products.locator.how.it.works.1.description": "See where important people are on a map, no on-device app needed",
    "products.locator.how.it.works.2.header": "Checks",
    "products.locator.how.it.works.2.description": "Get automatic location alerts at the same time daily",
    "products.locator.how.it.works.3.header": "History",
    "products.locator.how.it.works.3.description": "Keep track of where they've been",

    // ABOUT-US: Header
    "about.us.header": "Local company, global reach",
    "about.us.tagline": "We make great products in a beautiful place.",

    "about.us.hero.hover.description": "East Bay Awesomeness",
    "about.us.hero.hover.credit": "Shot by our lead engineer Brian Martin",

    "about.us.avast.header": "Better together",
    "about.us.avast.tagline": "Location Labs is now part of the Avast team! Together, we’re able to offer our customers the best security and safety products in the world. We help protect people—their devices, their data, and their families.",

    // ABOUT-US: People List
    "about.us.people.header": "We're all about growth: yours and ours",
    "about.us.people.tagline": "Location Labs started in 2002 as a two-person operation. Today, we’re a team of 150 plus—and growing! We attribute part of that success to our meritocracy: promotion based on ability. We invest in our people to help them grow their careers. That’s why so many “Labbers” stick around for years.",

    // ABOUT-US: Gagan Singh
    "about.us.people.gagan.singh.name": "Gagan Singh",
    "about.us.people.gagan.singh.position": "SVP GM Avast Mobile, Head Location Labs",
    "about.us.people.gagan.singh.bio": "Gagan is SVP and GM at Avast (our parent company), and is head of Location Labs. He has led Avast's fastest growing Mobile Business Unit with over 170M users since August 2015. Gagan holds an MBA from MIT, an MPA from Harvard, and a BS in Electrical Engineering and Computer Science from UC Berkeley.",

    // ABOUT-US: Jarret Cuisinier
    "about.us.people.jarret.cuisinier.name": "Jarret Cuisinier",
    "about.us.people.jarret.cuisinier.position": "VP Marketing & Operations",
    "about.us.people.jarret.cuisinier.bio": "Jarret leads Location Labs' marketing and business operations functions, including digital marketing, product marketing, market development, and customer support. He also is responsible for Avast Mobile's consumer marketing, business analytics, and sales ops functions.",

    // ABOUT-US: Leena Elias
    "about.us.people.leena.elias.name": "Leena Elias",
    "about.us.people.leena.elias.position": "VP Partner Delivery",
    "about.us.people.leena.elias.bio": "Leena joined Avast in June of 2018 and is responsible for delivery of Avast's consumer applications to external partners. She leads the Product and Project Management teams that focus on design and customization of Avast's solutions for our customers.",

    // ABOUT-US: Justin Safdie
    "about.us.people.justin.safdie.name": "Justin Safdie",
    "about.us.people.justin.safdie.position": "VP Engineering",
    "about.us.people.justin.safdie.bio": "Justin is responsible for all aspects of product delivery including product deployment, hosting, maintenance, systems integration and technical support. He has led the launch of multiple tier 1 products, and is responsible for maintaining our industry-leading track record of delivering scalable, secure services for our partners and customers.",

    // ABOUT-US: Todd Hodes
    "about.us.people.todd.hodes.name": "Todd Hodes PH.D.",
    "about.us.people.todd.hodes.position": "Head of Mobile Engineering",
    "about.us.people.todd.hodes.bio": "Author of seventeen papers and patents, Todd is Senior Scientist and Director of Mobile Technology at Location Labs. He was one of the earliest members of the engineering team at Location Labs, joining after completing his PhD in Computer Science at UC Berkeley, and has never looked back.",

    // ABOUT-US: Call to Action
    "about.us.call.to.action.header": "Stay in touch",

    // ABOUT-US: Map
    "about.us.map.hq": "Location Labs HQ",

    // CAREERS
    "careers.header": "Join our team",
    "careers.button.view.positions": "View Positions",

    // CAREERS: Hero Hover Text
    "careers.hero.hover.description": "Farm to Table party",
    "careers.hero.hover.credit": "Shot by infrastructure engineer John Ubante",

    // CAREERS: Shield Hover Text
    "careers.shield.description": "This is our beloved spartan shield trophy! Awarded to our most impactful team-mates.",

    // CAREERS: Success
    "careers.success.header": "Successful products. Smart people. Relaxed vibe.",
    "careers.success.tagline": "Join the crew at Location Labs, and you’ll contribute to successful products and services that help millions of parents and kids safely use mobile technology. You’ll work alongside smart people you respect and whose company you genuinely enjoy. Our team is inclusive, open-minded, and down-to-earth.",

    // CAREERS: How we do it
    "careers.how.we.do.it.header": "This is how we do it.",
    "careers.how.we.do.it.tagline": "We live our values. For real. They have guided our behavior as individuals and as an organization since 2002, helping us build a solid company and create a fantastic place to work. If they speak to you, let’s talk.",
    "careers.how.we.do.it.initiative.header": "Initiative drives our meritocracy",
    "careers.how.we.do.it.initiative.description": "We reward people for initiative - as proven by our track record of promoting from within.",
    "careers.how.we.do.it.phalanx.header": "We win as a phalanx",
    "careers.how.we.do.it.phalanx.description": "We put team victory ahead of personal gains.",
    "careers.how.we.do.it.partners.header": "We are great partners",
    "careers.how.we.do.it.partners.description": "We owe our success to our partner relationships, and we take that seriously.",
    "careers.how.we.do.it.craftsmanship.header": "We take pride in craftsmanship",
    "careers.how.we.do.it.craftsmanship.description": "We work within serious constraints, and these limits often push us to find breakthrough solutions.",
    "careers.how.we.do.it.underdogs.header": "We are unentitled and resilient",
    "careers.how.we.do.it.underdogs.description": "Driven by an underdog’s grit, we have a tenacity and determination that other companies don’t.",
    "careers.how.we.do.it.positivity.header": "We exude infectious positivity",
    "careers.how.we.do.it.positivity.description": "We’re devoted to the things that inspire us, and find fun ways to share them with each other.",
    "careers.how.we.do.it.passions.header": "We share our passions",
    "careers.how.we.do.it.passions.description": "Join one of the over a dozen Labber social clubs.",

    // CAREERS: Benefits
    "careers.benefits.header": "Benefits",
    "careers.benefits.health.header": "Health &amp; Wellness",
    "careers.benefits.health.description": "We offer very competitive and comprehensive welfare benefits to employees and their families, and great perks! Free onsite chair massage? You got it. Onsite gym? You bet.",
    "careers.benefits.parties.header": "Great Parties",
    "careers.benefits.parties.description": "We believe in celebrating success with rafting trips, farm-to-table outings, product demos with snacks, and seasonal company parties!",
    "careers.benefits.people.header": "Amazing People",
    "careers.benefits.people.description": "We hire for spirit, drive, and diversity of experience, making it easy to find mentors and kindred spirts at our happy hours and events.",
    "careers.benefits.growth.header": "Serious Career Growth",
    "careers.benefits.growth.description": "Bring your talent and initiative, and assume no constraints. Show us what you can do and we'll do everything we can to support you.",
    "careers.benefits.culture.header": "Inspiring Culture",
    "careers.benefits.culture.description": "You feel it when you walk in the door: culture means a lot to us. That's why we’re upfront about our values, and hire accordingly.",
    "careers.benefits.location.header": "East Bay Location",
    "careers.benefits.location.description": "We have gorgeous views and private parking, plus proximity to public transit options and The Bay Trail for bike commuting!",

    // CAREERS: Engineering
    "careers.engineering.header": "Gold-standard engineering",
    "careers.engineering.challenges.header": "Big challenges; massive scale",
    "careers.engineering.challenges.description": "We solve difficult software problems to develop mobile and web experiences that millions of people use every day. We build and integrate into wireless operator network services that regularly process thousands of transactions per second.",
    "careers.engineering.automation.header": "Smart automation and monitoring",
    "careers.engineering.automation.description": "We embrace automation and tools to streamline development, testing and deployment including customized tools we’ve built on Fabric, Jenkins, and the Atlassian suite. We monitor and maintain products through our 24/7 network operations center (NOC) using Splunk, Graphite, and Nagios.",
    "careers.engineering.open.header": "Open Source",
    "careers.engineering.open.description": "We build mobile applications for Android and iOS using leading open-source frameworks like Butterknife, Dagger, Swinject, and PromiseKit. We regularly contribute to existing open-source projects, and have open-sourced some of our own projects as well.",

    // CAREERS: Apply
    "careers.apply.header": "How to apply",
    "careers.apply.tagline": "Check the available positions on our job listings page, and send in your resume! When you do, tell us about your work experience, but also let us know about what makes you tick. Help us get to know who you are, what you care about—and why you’re interested in Location Labs. Everyone has a story; we want to hear yours.",

    // NEWS
    "news.announcement": "Announcement",
    "news.article": "Article",
    "news.awards": "Awards",
    "news.header": "Press Center",
    "news.item.link.title": "Location Labs by Avast",
    "news.more.announcements": "More Announcements",
    "news.more.articles": "More Articles",
    "news.more.awards": "More Awards",
    "news.read.the.article": "Read the Article",
    "news.tagline": "The latest news",

    // NOT FOUND
    "not.found.subtext": "This is not the page you were looking for",
    "not.found.continue.button": "Move along"

  };
});