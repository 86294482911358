define('llwww/utils/inject-script', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = injectScript;

  /**
   * Util to load external script
   * @param  {string} src url for the script
   * @return {Promise}    Promise that resolves when the script is loaded
   */

  function injectScript(src) {
    return new _ember['default'].RSVP.Promise(function (resolve) {
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = src;
      script.onload = function () {
        resolve();
      };
      document.getElementsByTagName('head')[0].appendChild(script);
    });
  }
});