define('llwww/components/nav-header', ['exports', 'ember'], function (exports, _ember) {

   var $ = _ember['default'].$;

   exports['default'] = _ember['default'].Component.extend({

      tagName: 'header',

      _onTransitionEnd: function _onTransitionEnd() {
         if ($('nav').hasClass('mobile-hidden')) {
            $('nav').addClass('animation-finished');
         } else {
            $('nav').removeClass('animation-finished');
         }
      },

      /*
         Used for toggling hamburger menu.
      */
      didInsertElement: function didInsertElement() {
         // Listens for CSS transition end.  Can't animate with display: none,
         // so add that class after the animation finishes.
         $('nav').on('transitionend', this._onTransitionEnd);
      },

      willDestroyElement: function willDestroyElement() {
         $('nav').off('transitionend', this._onTransitionEnd);
      },

      actions: {

         toggleNav: function toggleNav() {
            $('nav').toggleClass('mobile-hidden');
         },

         hideNav: function hideNav() {
            $('nav').addClass('mobile-hidden');
         }
      }
   });
});