define('llwww/initializers/emailjs', ['exports', 'llwww/config/environment', 'llwww/utils/inject-script'], function (exports, _llwwwConfigEnvironment, _llwwwUtilsInjectScript) {
   exports.initialize = initialize;

   function initialize() {
      // inject emailjs script and initialize when it's loaded.
      (0, _llwwwUtilsInjectScript['default'])('https://cdn.emailjs.com/dist/email.min.js').then(function () {
         emailjs.init(_llwwwConfigEnvironment['default'].APP.EMAILJS.userId);
      });
   }

   exports['default'] = {
      name: 'emailjs',
      initialize: initialize
   };
});