define('llwww/routes/contact', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Route.extend({
      beforeModel: function beforeModel(transition) {
         transition.abort();

         // The contact section is on the about page.
         this.transitionTo('about').then(function () {

            // After the page has rendered,
            // scroll page to #contact-us element
            _ember['default'].run.schedule('afterRender', function () {
               window.location.replace('/about#contact-us');
            });
         });
      }
   });
});