define('llwww/components/google-tag-manager', ['exports', 'ember'], function (exports, _ember) {

    /**
     * Component for Google Tag Manager
     */
    exports['default'] = _ember['default'].Component.extend({

        id: '',

        auth: '',

        preview: '',

        init: function init() {
            this._super();

            /* jshint ignore:start */
            var auth = this.get('auth');
            var preview = this.get('preview');

            (function (w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({
                    'gtm.start': new Date().getTime(),
                    event: 'gtm.js'
                });
                var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s),
                    dl = l != 'dataLayer' ? '&l=' + l : '';
                j.async = true;
                j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl + ('&gtm_auth=' + auth + '&gtm_preview=' + preview + '&gtm_cookies_win=x');
                f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', this.get('id'));
            /* jshint ignore:end */
        }
    });
});