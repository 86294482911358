define('llwww/routes/news', ['exports', 'ember', 'llwww/mixins/reset-scroll', 'rsvp'], function (exports, _ember, _llwwwMixinsResetScroll, _rsvp) {
   exports['default'] = _ember['default'].Route.extend(_llwwwMixinsResetScroll['default'], {

      newsPage: _ember['default'].inject.service(),

      model: function model() {
         var newsPage = this.get('newsPage');
         return _rsvp['default'].hash({
            announcements: newsPage.getPagesLoaded('announcements'),
            awards: newsPage.getPagesLoaded('awards'),
            articles: newsPage.getPagesLoaded('articles')
         });
      },

      actions: {
         closeModal: function closeModal() {
            this.transitionTo('news');
         },

         showMore: function showMore(category) {
            this.get('newsPage').getNextPage(category);
         }
      }
   });
});