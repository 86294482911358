define('llwww/components/mapbox-map', ['exports', 'ember', 'npm:mapbox-gl/dist/mapbox-gl.js', 'llwww/properties', 'llwww/config/environment'], function (exports, _ember, _npmMapboxGlDistMapboxGlJs, _llwwwProperties, _llwwwConfigEnvironment) {
   exports['default'] = _ember['default'].Component.extend({
      i18n: _ember['default'].inject.service(),

      // Required to ensure this component can be stretched to 100% width/height
      tagName: '',

      didInsertElement: function didInsertElement() {
         var locationLabsHQ = _llwwwProperties['default'].corporate.address.location;

         // Set the access token and create the map
         _npmMapboxGlDistMapboxGlJs['default'].accessToken = _llwwwConfigEnvironment['default'].APP.MAPBOX_ACCESS_TOKEN;
         var map = new _npmMapboxGlDistMapboxGlJs['default'].Map({
            container: 'mapbox-map-container',
            style: 'mapbox://styles/mapbox/light-v9',
            center: locationLabsHQ,
            zoom: 14
         });

         // If controls aren't appearing on your map, it's likely that you don't have the
         // mapbox css included properly.
         map.addControl(new _npmMapboxGlDistMapboxGlJs['default'].FullscreenControl(), 'top-left');
         map.addControl(new _npmMapboxGlDistMapboxGlJs['default'].NavigationControl());

         // Custom pin
         var pin = document.createElement('div');
         pin.className = 'pin';
         pin.title = this.get('i18n').t('about.us.map.hq');
         var pinOptions = {
            offset: {
               x: 0,
               y: -73
            }
         };

         // Shadow below the pin
         var shadow = document.createElement('div');
         shadow.className = 'shadow';

         // Add the pin/shadow markers to the map
         new _npmMapboxGlDistMapboxGlJs['default'].Marker(shadow).setLngLat(locationLabsHQ).addTo(map);
         new _npmMapboxGlDistMapboxGlJs['default'].Marker(pin, pinOptions).setLngLat(locationLabsHQ).addTo(map);
      }
   });
});
// Mapbox has two components - javascript and css. Both are included in the npm module,
// but they're imported in different ways. The js is imported via ember-browserify
// (specify npm: in the import/from statement). The css is appended to the vendor.css
// generated file via ember-cli-node-assets. You can configure the css import within
// ember-cli-build.js.