define('llwww/routes/application', ['exports', 'ember', 'llwww/config/environment'], function (exports, _ember, _llwwwConfigEnvironment) {
   exports['default'] = _ember['default'].Route.extend({

      model: function model() {
         return {
            gtm: {
               id: _llwwwConfigEnvironment['default'].APP.GTM.ID,
               auth: _llwwwConfigEnvironment['default'].APP.GTM.AUTH,
               preview: _llwwwConfigEnvironment['default'].APP.GTM.PREVIEW
            }
         };
      }
   });
});