define('llwww/routes/careers', ['exports', 'ember', 'llwww/mixins/reset-scroll'], function (exports, _ember, _llwwwMixinsResetScroll) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  exports['default'] = _ember['default'].Route.extend(_llwwwMixinsResetScroll['default'], {
    instagramService: _ember['default'].inject.service('instagram'),
    /**
     * howWeDoIt,
     * benefits:         These are the keys that are looped over to display feature-boxes
     *                   (header with paragraph.)
     * slideshowImages:  These are the class names for the images in the slideshow.
     * instagramImages:  These are the URLs for the instagram gallery.
     */
    model: function model() {
      var model = {
        "howWeDoIt": ['initiative', 'phalanx', 'partners', 'craftsmanship', 'underdogs', 'positivity', 'passions'],
        "benefits": ['health', 'parties', 'people', 'growth', 'culture', 'location'],
        "slideshowImages": ['image-ping-pong', 'image-sticky-notes', 'image-dog', 'image-coffee', 'image-on-laptop', 'image-snacks'],
        "instagramImages": []
      };

      return this.get('instagramService').getRecentMedia().then(function (data) {
        var listItems = data.items.map(function (item) {
          return item.link;
        }).slice(0, 3);
        return _extends({}, model, {
          instagramImages: listItems
        });
      }, function (err) {
        var defaultImages = ['https://www.instagram.com/p/BZXIAWQA1rb/', 'https://www.instagram.com/p/BYwcDeKAaV6/', 'https://www.instagram.com/p/BYwJw1BgwQW/'];
        return _extends({}, model, {
          instagramImages: defaultImages
        });
      });
    }
  });
});