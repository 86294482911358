define('llwww/helpers/properties', ['exports', 'ember', 'llwww/properties'], function (exports, _ember, _llwwwProperties) {
  function _toArray(arr) { return Array.isArray(arr) ? arr : Array.from(arr); }

  /**
   * Helper to get values from properties in handlebar templates
   * @param  {string[]}    [...keys])    list of keys to access property. Each of the value will be joined using "."
   * @return {*}                         value from properties
   */
  exports['default'] = _ember['default'].Helper.helper(function (_ref) {
    var _ref2 = _toArray(_ref);

    var keys = _ref2;

    return _llwwwProperties['default'].get(keys.join('.'));
  });
});