define('llwww/components/global-footer', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
   exports['default'] = _ember['default'].Component.extend({

      tagName: "footer",

      year: _ember['default'].computed(function () {
         return (0, _moment['default'])().year();
      }),

      socialNetworks: ['github', 'instagram', 'linkedin'],

      footerLinks: ['index', 'careers', 'about', 'products', 'contact', 'privacy', 'partners', 'news']
   });
});