define('llwww/routes/about', ['exports', 'ember', 'llwww/mixins/reset-scroll'], function (exports, _ember, _llwwwMixinsResetScroll) {
   exports['default'] = _ember['default'].Route.extend(_llwwwMixinsResetScroll['default'], {

      /**
       * These are the keys used in translations.js, as well as the names of their photos.
       */
      model: function model() {
         return ['gagan.singh', 'jarret.cuisinier', 'justin.safdie', 'leena.elias', 'todd.hodes'];
      }
   });
});