define('llwww/router', ['exports', 'ember', 'llwww/config/environment'], function (exports, _ember, _llwwwConfigEnvironment) {

   var Router = _ember['default'].Router.extend({
      location: _llwwwConfigEnvironment['default'].locationType,
      rootURL: _llwwwConfigEnvironment['default'].rootURL
   });

   Router.map(function () {
      this.route('about');
      this.route('blog');
      this.route('careers');
      this.route('contact');
      this.route('news', function () {
         this.route('article', { path: 'article/:id' });
         this.route('announcement', { path: 'announcement/:id' });
      });
      this.route('partners');
      this.route('products');

      this.route('privacy', { path: 'privacy-policy' });
      this.route('not-found', { path: "/*path" });
   });

   exports['default'] = Router;
});